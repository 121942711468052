import React from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const VersionSelector = ({
  name,
  versionsData = [],
  selectedVersion,
  onVersionChange,
}) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={3} key={`${name}`}>
        <FormControl
          fullWidth
          size="small"
          style={{ minWidth: '250px' }} // Set the minimum width here
        >
          <InputLabel>{`${name}`}</InputLabel>
          <Select
            key={`${name}`}
            label={`${name}`}
            value={selectedVersion}
            onChange={onVersionChange}
            size="small"
          >
            {versionsData.map((vData) => (
              <MenuItem key={`${name}${vData.version}`} value={vData.version}>
                {`${vData.version} - ${vData.description}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default VersionSelector;
