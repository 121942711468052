import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import {
  ecgStripType,
  containerChildProps,
} from 'common/constants/sharedPropTypes';
import { lineGen } from '../utils';

const D3CurveLine = ({
  parent,
  transform,
  lineColor,
  strokeWidth,
  data = [],
  analyzedBeats = [],
}) => {
  const { clip, xScale, yScale, options = {}, scale } = parent;
  const { xPropKey, yPropKey } = options;

  const line = useCallback(
    (...args) => lineGen({ x: xPropKey, y: yPropKey }, yScale)(...args),
    [yScale, xPropKey, yPropKey]
  );

  const renderCircles = (inputPeaks, ecgData) => {
    // eslint-disable-next-line
    const [hoveredData, setHoveredData] = useState(null);

    if (!inputPeaks.length || !ecgData.length) {
      return null;
    }

    const peakTimesMap = new Map();
    inputPeaks.forEach((item) => {
      item.peaks.forEach(({ dateFrom: peaksTime, value }) => {
        peakTimesMap.set(peaksTime, { color: item.color, value });
      });
    });

    return (
      <>
        {ecgData.map((dataItem) => {
          const { time: dataTime, value } = dataItem;
          if (peakTimesMap.has(dataTime)) {
            const cx = (scale || xScale)(new Date(dataTime));
            const cy = yScale(value);
            const { color, value: peakValue } = peakTimesMap.get(dataTime);

            return (
              <circle
                key={dataTime}
                cx={cx}
                cy={cy}
                r={5}
                fill={color}
                onMouseEnter={() => setHoveredData({ cx, cy, peakValue })}
                onMouseLeave={() => setHoveredData(null)}
              />
            );
          }
          return null;
        })}

        {hoveredData && (
          <text x={hoveredData.cx} y={hoveredData.cy - 10} textAnchor="middle">
            {hoveredData.peakValue}
          </text>
        )}
      </>
    );
  };

  return (
    <>
      <path
        fill="none"
        clipPath={clip}
        stroke={lineColor}
        strokeWidth={strokeWidth}
        d={line(data, scale || xScale)}
        transform={transform}
      />
      {renderCircles(analyzedBeats, data)}
    </>
  );
};

D3CurveLine.defaultProps = {
  strokeWidth: 1,
  lineColor: 'black',
};

D3CurveLine.propTypes = {
  parent: containerChildProps,
  transform: PropTypes.string,
  lineColor: PropTypes.string,
  strokeWidth: PropTypes.number,
  data: PropTypes.arrayOf(ecgStripType),
  analyzedBeats: PropTypes.array,
};

export default memo(D3CurveLine);
